import store from '@/store'
var moment = require('moment')
export function validateEmail (email) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}
export function PhoneNumberValidate (number) {
  const pattern = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/
  return pattern.test(number)
}
export function checkSpeialChar(str) {
  const specialChars = `\`!@#$%^&*()_+-=[]{};':"\\|,.<>/?~`;
  const result = specialChars.split('').some(specialChar => {
    if (str.includes(specialChar)) {
      return true;
    }
    return false;
  });
  return result;
}
// :-,;"'`*#@
export function validateNumber (value) {
  value = value.trim()
  var newVal = value.replace(/[^0-9A-Za-z]/g, '')
  if (newVal.substring(0, 1) === '1') {
    newVal = newVal.substring(1)
  }
  if (newVal.length === 3) {
    return newVal.replace(/[^0-9]/g, '').replace(/(\d{3})/, '($1)')
  } else if (newVal.length === 6) {
    return newVal.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})/, '($1) $2')
  } else if (newVal.length === 10) {
    return newVal.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3 ')
  } else if (newVal.length >= 11 && value.indexOf(')') < 0) {
    var string = value.replace(/[^0-9A-Za-z:,;"'`*#@ -]/g, '')
    if (string.substring(0, 1) === '+') {
      string = newVal.substring(1)
    }
    if (string.substring(0, 1) === '1') {
      string = newVal.substring(1)
    }
    var formate = string.replace(/[^0-9]/g, '').substring(0, 10)
    var numberFormate = formate.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    return numberFormate + ' ' + string.substring(10)
    // this.number = value.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})([0-9A-Za-z]{4})$/, '($1) $2-$3 $4')
  } else {
    if (value.substring(0, 1) === '+') {
      value = value.substring(1)
    }
    if (value.substring(0, 1) === '1') {
      value = value.substring(1)
    }
    return value
  }
}
export function ReFormatDate (date) {
  if (date === null) {
    return null
  }
  var newDateArray = date.split('-')
  return newDateArray[1] + '/' + newDateArray[2] + '/' + newDateArray[0]
}

// export function ReFormatDateTime (date) {
//   if (date === null) {
//     return null
//   }
//   dateTimeAry = date.split(' ')
//   var newDateArray = dateTimeAry[0].split('-')
//   return newDateArray[1] + '/' + newDateArray[2] + '/' + newDateArray[0]
// }

export function ReFormatNumber (number) {
  return number.replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}
export function IntToTime (time) {
  var minutes = parseInt(time % 60, 10)
  var hours = parseInt(time / 60 % 24, 10)
  return hours + ':' + minutes
}
export function OnlyTimeFilter (dateTime) {
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  return moment(time).format(formate)
}

export function validateEmailORPhone (emailphone) {
  const pattern = /^(?:\d{10}|\w+@\w+\.\w{2,3})$/
  return pattern.test(emailphone)
}

export function OnlyTimeFilterWithoutZero (dateTime) {
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  let formatTime = moment(time).format(formate)
  formatTime = formatTime.toLowerCase()
  if (formatTime.indexOf('am') > 0 || formatTime.indexOf('pm') > 0) {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
    let formatTimeArray = formatTime.split(':')
    if (formatTimeArray[1].substring(0, 2) === '00') {
      formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].split(' ')[1]
    } else {
      formatTime = formatTimeArray[0] + ':' + formatTimeArray[1]
    }
    // else if (formatTimeArray[1].substring(0, 1) === '0') {
    //   formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].substring(1)
    // }
  } else {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
  }
  return formatTime
}
